import { template as template_9a6f738ecd044c678b06cad541fee3cd } from "@ember/template-compiler";
const FKText = template_9a6f738ecd044c678b06cad541fee3cd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
