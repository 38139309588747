import { template as template_51b648dbb08c460d90ed2c4be0101f2d } from "@ember/template-compiler";
const SidebarSectionMessage = template_51b648dbb08c460d90ed2c4be0101f2d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
