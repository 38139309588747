import { template as template_51a6093a088e4c709e0b5f8e9fd02c13 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_51a6093a088e4c709e0b5f8e9fd02c13(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
