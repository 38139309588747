import { template as template_5ac5c4f78a714f5e824f5b2c40391167 } from "@ember/template-compiler";
const FKLabel = template_5ac5c4f78a714f5e824f5b2c40391167(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
