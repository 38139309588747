import { template as template_e73c7f0ae9b14c72a9f8a052a3670f91 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import { i18n } from "discourse-i18n";
const UserSummarySection = template_e73c7f0ae9b14c72a9f8a052a3670f91(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
